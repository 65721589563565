<template>
  <div>Redirecting to app...</div>
</template>

<script>
/*
  This component will load after successful twitter login from a separate broswer window
  Twitter sigin popup opens from login page or account page when linking Trvio account to twitter.
  If redirected from login page, close this window then reload to start page or bookmarked page by the user
  If redirected from accounts page close this window and reload the accounts page
*/
export default {

  created() {
    window.onunload = function () {
      window.opener.location = `${window.location.origin}/account?startuptab=3`
    }  
    // if(this.$route.query.opener === 'login') {
    //   window.onunload = function () {
    //     // TODO: Set local storage value for acess token and refresh token
    //     window.opener.location.pathname = this.$route.redirectRoute;
    //   }  
    // } 
  },
  mounted () {
    window.close()
  }
}
</script>

<style>

</style>